import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c(VCard,{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('admin.route.regions')}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 pt-3",style:(_vm.getViewHeightStyle(36))},[_c(VList,{staticClass:"ma-1",attrs:{"two-line":""}},_vm._l((_vm.getRegions),function(region,i){return _c('div',{key:i},[_c(VListItem,{class:i % 2 === 0 ? 'white' : 'grey lighten-5',on:{"click":function($event){return _vm.selectRegion(region.id)}}},[_c(VListItemAvatar,[(!!region.countryShort)?_c(VImg,{attrs:{"src":("https://flagcdn.com/" + (region.countryShort) + ".svg")}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(region.name)}})],1),_c(VListItemAction,{staticClass:"px-3 pt-3"},[_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(region.id === _vm.item.regionId ? "mdi-radiobox-marked" : "mdi-radiobox-blank")+" ")])],1)],1),_c(VDivider)],1)}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }