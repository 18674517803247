<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.regions')">
        <!-- <v-btn small text color="success" class="mx-2" @click="save">
          <v-icon>mdi-content-save</v-icon>
          <span class="text--secondary">{{ $t("general.save") }}</span>
        </v-btn> -->
      </toolbar>

      <v-card flat>
        <v-card-text
          class="overflow-y-auto px-0 pt-3"
          :style="getViewHeightStyle(36)"
        >
          <v-list class="ma-1" two-line>
            <div v-for="(region, i) in getRegions" :key="i">
              <v-list-item
                :class="i % 2 === 0 ? 'white' : 'grey lighten-5'"
                @click="selectRegion(region.id)"
              >
                <v-list-item-avatar>
                  <v-img
                    v-if="!!region.countryShort"
                    :src="`https://flagcdn.com/${region.countryShort}.svg`"
                  ></v-img>
                  <!-- <v-img v-if="!!item.countryShort" alt="Logo" :src="getServerFile(item.logo)"></v-img> -->
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="region.name" />
                </v-list-item-content>
                <v-list-item-action class="px-3 pt-3">
                  <v-icon large color="primary">
                    {{
                      region.id === item.regionId
                        ? "mdi-radiobox-marked"
                        : "mdi-radiobox-blank"
                    }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
              <!-- <v-divider
              v-if="
                notEmptyArray(getRegions) && i !== getRegions.length - 1
              "
            /> -->
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card>
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "CustomerRegion",
  components: { ViewContainer, Toolbar },
  data() {
    return {
      item: { id: null, regionId: null },
    };
  },
  mounted() {
    this.loadRegions();
    let user = this.getUserById(this.idParam);
    if (user) {
      this.item = user;
    } else {
      this.loadUser(this.idParam).then((data) => {
        this.item = data;
      });
    }
  },
  computed: {
    ...mapGetters("users", ["getUserById"]),
    ...mapGetters("regions", ["getRegions"]),

    idParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions("users", ["loadUser", "updateUser"]),
    ...mapActions("regions", ["loadRegions"]),

    selectRegion(regionId) {
      this.updateUser({ path: `/region/${regionId}`, item: this.item }).then(
        (data) => (this.item = data)
      );
    },
  },
};
</script>
